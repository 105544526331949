"use client";
import React, { useEffect, useState } from "react";
import TripCard from "./TripCard";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import CardSkeleton from "./CardSkeleton";
import Skeleton from "react-loading-skeleton";

const ExploreSection = () => {
  const [trips, setTrips] = useState([]);
  const axiosURL = globalAxiosURL();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const { data } = await axiosURL.get("/trip-packages");
        setTrips(data.data);
      } catch (error) {
        console.log("Error:", error);
      }
      setLoading(false);
    };

    fetchTrips();
  }, [axiosURL]);

  return (
    <div data-aos="fade-up" className="max-w-[1200px] mx-auto px-4 md:px-0">
      <div className="text-center mb-4">
        <h1 className="text-3xl p-1">The Places You Must Visit</h1>
        <div className="p-1">
          <p className="text-xl">
            Visit new places and chosen destinations of your interests with
          </p>
          <p className="text-xl">HAMS Fly.</p>
        </div>
      </div>
      <div
        // data-aos="fade-up"
        className="flex md:grid grid-cols-3 gap-10 mt-16 overflow-x-auto md:overflow-visible"
      >
        {loading &&
          Array.from({ length: 3 }).map((_, i) => <CardSkeleton key={i} />)}

        {trips.map((trip) => (
          <TripCard key={trip._id} trip={trip} loading={loading} />
        ))}
      </div>
    </div>
  );
};

export default ExploreSection;
