"use client";
import React, { useState } from "react";

const FaqSection = () => {
  // State to keep track of the active FAQ
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle the visibility of the FAQ answer
  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // FAQ data
  const faqs = [
    {
      question:
        "How do I find the best sites for finding cheap flights deals on HAMS Fly?",
      answer:
        "Simply enter your travel details (destination, dates, number of passengers) into our search tool, and we will show you the best options from a variety of airlines and travel agents. You can filter results by price, duration, or preferred airlines to find the perfect flight for you.",
    },
    {
      question: "Can I book flights for multiple passengers at once?",
      answer:
        "Yes! HAMS Fly makes it easy to book flights for multiple passengers at the same time. Just enter the number of travelers and we will display options for all of you. You can even choose seats together for a smoother experience.",
    },
    {
      question: "Are there any hidden fees when I book my flight?",
      answer:
        "No, we believe in transparency. The price you see is the price you pay. There are no hidden fees or surprise charges when booking with HAMS Fly. You will see the total cost upfront before confirming your booking",
    },
    {
      question: "How do I change or cancel my flight after booking?",
      answer:
        "If you need to change or cancel your flight, please contact our customer support team as soon as possible. They will guide you through the process and help you with any changes, subject to airline policies. Be aware that some changes may involve additional fees.",
    },
    {
      question: "Can I book a last-minute flight with HAMS Fly?",
      answer:
        "Absolutely! We offer last-minute flight deals for those who need to travel urgently. Simply search for your desired dates, and we will show you available flights. You may even find exclusive discounts on last-minute bookings.",
    },
    {
      question: "How do I know if my flight is confirmed after booking?",
      answer:
        "Once your booking is complete, you will receive a confirmation email with your flight details and booking reference number. If you do not receive this, please check your spam folder or contact us to ensure your booking was processed correctly.",
    },
    {
      question: "Can I book a last-minute flight with HAMS Fly?",
      answer:
        "Absolutely! We offer last-minute flight deals for those who need to travel urgently. Simply search for your desired dates, and we will show you available flights. You may even find exclusive discounts on last-minute bookings.",
    },
    {
      question: "What payment methods can I use to book my flight?",
      answer:
        "We accept various payment methods including credit/debit cards, PayPal, and other secure online payment options. All payments are processed safely and securely.",
    },
    {
      question: "Can I book flights for someone else?",
      answer:
        "Yes, you can book flights for family members, friends, or colleagues. Just make sure to enter their correct details (name, passport info, etc.) during the booking process.",
    },
    {
      question: "What happens if my flight is canceled or delayed?",
      answer:
        "If your flight is canceled or significantly delayed, contact our customer service team for assistance. We will help you explore rebooking options or alternative flights, depending on the airline's policies and available routes.",
    },
    {
      question:
        "How do I contact customer support if I need help with my booking?",
      answer:
        "You can reach our customer support team via email, live chat, or phone. Our friendly agents are available 24/7 to assist you with any questions or concerns regarding your booking.",
    },
    {
      question: "How does HAMS Fly work?",
      answer:
        "At HAMS Fly, we make booking your flights easy, affordable, and stress-free. Here is how it works: Search for Flights: Start by entering your travel details—destination, dates, number of passengers—into our simple search tool. Our platform will quickly show you a wide range of flight options from top airlines and trusted travel agents. Book Your Flight:Once you have found the flight that works for you, book it with just a few clicks. <span Enjoy the Best Deals: Hamsfly offers exclusive discounts and promotions to make sure you get the best price. You can even find last-minute deals, discounted first-class tickets, and flexible options to fit your travel plans.Customer Support Anytime: Our customer service team is available to assist you 24/7. Whether you need help booking your flight, making changes, or managing your booking, we are here to help every step of the way.",
    },
  ];

  return (
    <div data-aos="fade-up" className="space-y-0 relative">
      <div className="container max-w-[1200px] mx-auto px-4 lg:px-0">
        <h2 className="text-2xl font-bold mb-5">
        FAQs Cheap Get Air Ticket&apos;s
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => {
            const isActive = activeIndex === index;

            return (
              <div
                key={index}
                className={`border rounded-md p-1 cursor-pointer transition-all duration-1000 ${
                  isActive ? "bg-gray-100" : ""
                }`}
                onClick={() => toggleFAQ(index)}
                role="button"
                tabIndex={0}
                aria-expanded={isActive}
                onKeyDown={(e) => e.key === "Enter" && toggleFAQ(index)}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium">{faq.question}</h3>
                  <span className="text-xl font-bold">
                    {isActive ? "-" : "+"}
                  </span>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    isActive ? "max-h-[300px] mt-4" : "max-h-0"
                  }`}
                  style={{
                    height: isActive ? "auto" : "0px",
                  }}
                >
                  <p className="text-gray-700">{faq.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
